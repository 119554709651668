import React, {useState} from "react";
import "../../../../../sass/global/modals.scss";


export default function DeleteAccountRequestModal({modalVisible, closeModal, requestType, handleSubmitted}) {

    const [loading, setLoading] = useState('');
    const [text, setText] = useState('');

    const handleSubmit = () => {

        setLoading(true);
        const data = new FormData;
        data.append('text', text);
        data.append('type', requestType);

        axios.post(`/api/support-request`, data)
            .then(response => {
                setText('');
                handleSubmitted();
                closeModal();
            })
            .catch(e => console.error(e))
            .finally(() => setLoading(false))
    }

    return (
        <>
            {modalVisible && (
                <>
                    <div className="backdrop"></div>
                    <div className="modal modal--m">
                        <div className="modal__header pb--0">
                            <h4 className="mb--s"><i className="fad fa-user-slash mr--xs text--primary"></i> Zaslať
                                požiadavku na zrušenie účtu
                            </h4>

                            <button className={"modal__close"}
                                    onClick={closeModal}
                            >
                                <i className="fal fa-times"></i>
                            </button>
                        </div>

                        <form onSubmit={e => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                            <div className="modal__body">
                                <div className="modal__full gray">
                                    <p>Budeme veľmi radi, ak sa dozvieme dôvod Vašej žiadosti o zrušenie účtu. Udanie dôvodu však nie je povinné. Účet Vám bude zrušení po vybavení žiadosti administrátorom.</p>
                                    <div className="input-container mt--s">
                                        <label className={'input__label'}>Dôvod zrušenia účtu</label>
                                        <textarea className="input__item textarea l"
                                                  value={text}
                                                  onChange={e => {
                                                      setText(e.target.value)
                                                  }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal__footer">

                                <button className={"btn btn--text btn--xs mr--xs"}
                                        disabled={loading}
                                        onClick={() => {
                                            if (loading) return;
                                            closeModal()
                                        }}
                                >
                                    Zrušiť
                                </button>

                                <button type="submit" className={"btn btn--primary btn--xs btn--shadow"} disabled={loading}>
                                    {
                                        loading ? (
                                            <>
                                                <i className="fas fa-spinner fa-spin"/>
                                                &nbsp;
                                                Odosielam...
                                            </>
                                        ) : 'Odoslať'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )
            }
        </>
    )
}
